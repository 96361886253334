<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2023-03-16 20:19:01
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 充值
 * @FilePath: /java-pc/src/views/member/money/recharge.vue
-->
<template>
    <div class="sld_bankCard">
        <MemberTitle memberTitle="我的银行卡"></MemberTitle>
        <div class="sld_bankCard_con" v-if="opening">
            <div class="bank_top2">
                <div>银行卡信息</div>
                <el-button type="goon" size="small" @click="handleBack">返回上一页</el-button>
            </div>
            <div class="bank_center">
                <div class="bank_state">
                    <div class="step">
                        <span class="line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"></i>
                        <span>1.绑定银行卡</span>
                    </div>
                    <div class="step">
                        <span :class="[isBinding == 2 ? 'line' : 'no_line']"></span>
                        <i
                            class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"
                            :class="[isBinding != 2 ? 'no_sel' : '']"
                        ></i>
                        <span>2.短信验证</span>
                    </div>
                </div>
            </div>
            <div class="bank_bottom_Form">
                <!-- 绑定银行卡 start -->
                <el-form :model="bankForm" label-width="140px" v-if="isBinding == 1">
                    <el-form-item label="企业名称:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input v-model="companyName" placeholder="请输入企业名称" width="140px" disabled />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件类型:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input value="统一社会信用代码" placeholder="统一社会信用代码" width="140px" disabled />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="证件号码:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input v-model="enterpriseCode" placeholder="请输入证件号码" width="140px" disabled />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
          </el-form-item>-->
                    <el-form-item label="银行名称:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankName"
                                placeholder="请选择银行名称"
                                @focus="inputFocus"
                                @blur="inputBlur"
                                @input="inputName"
                                ref="inputRef"
                                :suffix-icon="isarrow1 ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                            />
                            <div class="search" v-if="isFocus" style="z-index: 999">
                                <div class="search_dialog">
                                    <div
                                        class="scrollbar-demo-item"
                                        v-for="(item, index) in bankList.data"
                                        :key="index"
                                        @mousedown="selectName(item)"
                                    >
                                        {{ item.bankName }}
                                    </div>
                                </div>
                                <!-- <el-scrollbar max-height="150px" class="search_dialog" tag="div" v-if="isFocus"> -->
                                <!-- <ul class="search_dialog"> -->

                                <!-- </ul> -->
                                <!-- </el-scrollbar> -->
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="开户银行所在省:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-select
                                v-model="nodeItem"
                                placeholder="请选择开户银行所在省"
                                v-if="!nodeList.length"
                            ></el-select>
                            <el-select v-model="nodeItem" filterable placeholder="请选择开户银行所在省" v-else>
                                <el-option
                                    v-for="item in nodeList"
                                    :key="item.provinceCode"
                                    :label="item.provinceName"
                                    :value="item.provinceCode"
                                />

                                <!-- <el-option label="Zone one" value="shanghai" />
                <el-option label="Zone two" value="beijing" />-->
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="开户银行所在市:" :required="true">
            <el-col :span="10" style="float: none;">
              <el-select v-model="cityItem" placeholder="请选择开户银行所在市" :no-data-text=" '暂无数据' " v-if="!cityList.length"></el-select>
              <el-select v-model="cityItem" placeholder="请选择开户银行所在市" v-else>
                <el-option
                  v-for="item in cityList"
                  :key="item.cityCode"
                  :label="item.cityName"
                  :value="item.cityCode"
                />
              </el-select>
            </el-col>
          </el-form-item> -->
                    <el-form-item label="开户银行所在市:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="cityvalue"
                                placeholder="请选择开户银行所在市"
                                @focus="inputFocus('cityItems')"
                                @blur="inputBlur('cityItems')"
                                @input="inputName_o('cityItems')"
                                ref="inputRef"
                                :suffix-icon="isarrow2 ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                            />
                            <div class="search" v-if="iscityBankFocus_o" style="z-index: 999">
                                <div class="search_dialog">
                                    <div
                                        class="scrollbar-demo-item"
                                        v-for="item in cityList"
                                        :key="item.cityCode"
                                        @mousedown="selectBank_o(item)"
                                    >
                                        {{ item.cityName }}
                                    </div>
                                </div>
                                <!-- <el-scrollbar max-height="150px" class="search_dialog" v-if="iscityBankFocus"> -->
                                <!-- <ul class="search_dialog"> -->
                                <!-- <div class="scrollbar-demo-item" v-for="item in cityBankList"
                                        :key="item.bankBnkcode" @mousedown="selectBank(item)">
                {{ item.bankLname }}</div>-->
                                <!-- </ul> -->
                                <!-- </el-scrollbar> -->
                            </div>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="开户行:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.region" placeholder="港澳回乡证">
                                <el-option label="Zone one" value="shanghai" />
                                <el-option label="Zone two" value="beijing" />
                            </el-select>
                        </el-col>
                    </el-form-item>
          <el-form-item label="开户行:">-->
                    <el-form-item label="开户行:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="cityBankItem"
                                placeholder="请选择开户行"
                                @focus="inputFocus('cityBank')"
                                @blur="inputBlur('cityBank')"
                                @input="inputName('cityBank')"
                                ref="inputRef"
                                :suffix-icon="isarrow3 ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                            />
                            <div class="search" v-if="iscityBankFocus" style="z-index: 999">
                                <div class="search_dialog">
                                    <div
                                        class="scrollbar-demo-item"
                                        v-for="item in cityBankList"
                                        :key="item.bankCode"
                                        @mousedown="selectBank(item)"
                                    >
                                        {{ item.bankName }}
                                    </div>
                                </div>
                                <!-- <el-scrollbar max-height="150px" class="search_dialog" v-if="iscityBankFocus"> -->
                                <!-- <ul class="search_dialog"> -->
                                <!-- <div class="scrollbar-demo-item" v-for="item in cityBankList"
                                        :key="item.bankBnkcode" @mousedown="selectBank(item)">
                {{ item.bankLname }}</div>-->
                                <!-- </ul> -->
                                <!-- </el-scrollbar> -->
                            </div>
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行类型:">
                        <el-col :span="10" style="float: none;">
                            <el-select v-model="bankForm.bankType" placeholder="港澳回乡证">
                                <el-option label="本行" value="1" />
                                <el-option label="外行" value="2" />
                            </el-select>
                        </el-col>
          </el-form-item>-->
                    <el-form-item label="银行卡号:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankForm.bankCardNo"
                                placeholder="请输入银行卡号"
                                type="number"
                                maxlength="30"
                            />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankForm.mobile"
                                placeholder="请输入手机号"
                                type="number"
                                maxlength="11"
                            />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人姓名:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input v-model="bankForm.enterpriseLegalName" placeholder="请输入法人姓名" />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人证件类型:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-select v-model="bankForm.reprGlobalType" placeholder="身份证">
                                <el-option label="身份证" value="1" />
                                <el-option label="港澳回乡证" value="3" />
                                <el-option label="台胞证" value="5" />
                                <el-option label="中国护照" value="4" />
                                <el-option label="外国护照" value="19" />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="法人证件号:" :required="true">
                        <el-col :span="10" style="float: none">
                            <el-input v-model="bankForm.reprGlobalId" placeholder="请输入法人证件号" />
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="经办人姓名" >
            <el-col :span="10" style="float: none;">
              <el-input v-model="bankForm.agencyClientName" placeholder="请输入经办人姓名"  maxlength="100"/>
            </el-col>
          </el-form-item>
          <el-form-item label="经办人证件类型:" >
            <el-col :span="10" style="float: none;">
              <el-select v-model="bankForm.agencyClientGlobalType" placeholder="身份证" width="100%">
                <el-option label="身份证" value="1" />
                <el-option label="港澳回乡证" value="3" />
                <el-option label="台胞证" value="5" />
                <el-option label="中国护照" value="4" />
                <el-option label="外国护照" value="19" />
              </el-select>
            </el-col>
          </el-form-item> -->
                    <!-- <el-form-item label="开户银行名称:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入开户银行" />
                        </el-col>
          </el-form-item>-->
                    <!-- <el-form-item label="经办人证件号:" >
            <el-col :span="10" style="float: none;">
              <el-input v-model="bankForm.agencyClientGlobalId" placeholder="请输入经办人证件号" maxlength="100"/>
            </el-col>
          </el-form-item> -->
                    <!-- <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none;">
                            <el-input v-model="bankForm.name" placeholder="请输入手机号" />
                        </el-col>
          </el-form-item>-->
                    <div class="next">
                        <div @click="subBank">下一步</div>
                    </div>
                </el-form>
                <!-- 绑定银行卡 end -->
                <!-- 打款验证 start -->
                <el-form :model="bankForm" label-width="120px" class="alone" v-if="isBinding == 2">
                    <el-form-item label="手机号">
                        <el-col :span="8" style="float: none">{{ bankForm.mobile }}</el-col>
                    </el-form-item>
                    <el-form-item label="鉴权序号" v-if="isShow == 1">
                        <el-col :span="8" style="float: none">
                            <el-input
                                v-model="testPhoneNum"
                                placeholder="请输入鉴权序号"
                                width="120px"
                                type="number"
                                maxlength="4"
                            />
                            <div class="timeOut1" @click="getTestNum">
                                {{ descNum ? descNum + "s后获取" : "获取鉴权序号" }}
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="打款金额:">
                        <el-col :span="8" style="float: none">
                            <el-input-number
                                v-model="bankMoney"
                                placeholder="请输入打款金额"
                                :controls="false"
                                :precision="2"
                                :min="0.01"
                                :max="0.5"
                            />
                            <!-- <el-input v-model="bankMoney" placeholder="请输入打款金额" width="120px" /> -->
                        </el-col>
                    </el-form-item>
                    <!-- <el-form-item label="银行卡号">
          <el-col :span="10" style="float: none;">-->
                    <!-- <el-input v-model="bankNum" placeholder="请输入银行卡号" /> -->
                    <!-- <el-input v-model="bankForm.bankCardNo" placeholder="请输入银行卡号" />
                        </el-col>
          </el-form-item>-->
                    <!-- <el-form-item>
                        <div>打款银行： 上海银行</div>
                    </el-form-item>
                    <el-form-item>
                        <div>银行账户： 1111111</div>
          </el-form-item>-->
                    <div class="next1" v-if="isShow == 1">
                        <div @click="sureBind">确认绑定</div>
                    </div>
                    <div class="next" v-else>
                        <div @click="getTestNum">确认绑定</div>
                    </div>
                </el-form>
                <!-- 打款验证 end -->
            </div>
        </div>
        <div class="sld_bankCard_con" v-else>
            <div class="bank_top2">
                <div>银行卡信息</div>
                <el-button type="goon" size="small" @click="handleBack">返回上一页</el-button>
            </div>
            <div class="bank_center">
                <div class="bank_state">
                    <div class="step">
                        <span class="line"></span>
                        <i class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"></i>
                        <span>1.绑定银行卡</span>
                    </div>
                    <div class="step">
                        <span :class="[isidentity == 2 ? 'line' : 'no_line']"></span>
                        <i
                            class="iconfont icon-querenyuanzhengqueduigoutijiaochenggongwancheng"
                            :class="[isBinding != 2 ? 'no_sel' : '']"
                        ></i>
                        <span>2.短信验证</span>
                    </div>
                </div>
            </div>
            <div class="bank_bottom_Form">
                <!-- 绑定银行卡 start -->
                <el-form :model="bankForms" label-width="120px" v-if="isBinding == 1">
                    <el-form-item label="银行名称:">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankName"
                                placeholder="请选择银行名称"
                                @focus="inputFocus"
                                @blur="inputBlur"
                                @input="inputName"
                                ref="inputRef"
                                :suffix-icon="isarrow1 ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                            />
                            <div class="search" v-if="isFocus" style="z-index: 999">
                                <div class="search_dialog">
                                    <div
                                        class="scrollbar-demo-item"
                                        v-for="(item, index) in bankList.data"
                                        :key="index"
                                        @mousedown="selectName(item)"
                                    >
                                        {{ item.bankName }}
                                    </div>
                                </div>
                                <!-- <el-scrollbar max-height="150px" class="search_dialog" v-if="isFocus"> -->
                                <!-- <ul class="search_dialog"> -->
                                <!-- <div class="scrollbar-demo-item" v-for="item in bankList.data" :key="item.bankCode"
                                        @mousedown="selectName(item)">
                {{ item.bankName }}</div>-->
                                <!-- </ul> -->
                                <!-- </el-scrollbar> -->
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="银行卡号:">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankForms.bankCardNo"
                                type="number"
                                placeholder="请输入银行卡号"
                                maxlength="30"
                            />
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号:">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="bankForms.mobile"
                                type="number"
                                disabled
                                placeholder="请输入手机号"
                                maxlength="11"
                            />
                        </el-col>
                    </el-form-item>
                    <div class="next2">
                        <div @click="subBank">下一步</div>
                    </div>
                </el-form>
                <!-- 绑定银行卡 end -->
                <!-- 打款验证 start -->
                <el-form :model="bankForm" label-width="120px" class="aloneB" v-if="isBinding == 2">
                    <el-form-item label="验证码" v-if="isShow == 1">
                        <el-col :span="10" style="float: none">
                            <el-input
                                v-model="testPhoneNum"
                                type="number"
                                maxlength="6"
                                placeholder="请输入验证码"
                                width="120px"
                            />
                            <div class="timeOut" @click="getTestNum">
                                {{ descNum ? descNum + "s后获取" : "获取验证码" }}
                            </div>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-col :span="10" style="float: none">{{ bankForms.mobile }}</el-col>
                    </el-form-item>
                    <div class="next">
                        <div @click="sureBind">确认绑定</div>
                    </div>
                </el-form>
                <!-- 打款验证 end -->
            </div>
        </div>
    </div>
</template>

<script>
import MemberTitle from "../../../components/MemberTitle";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, getCurrentInstance, computed, watch, onMounted, reactive } from "vue";
import { ElMessage, ElPagination, ElMessageBox, ElOption, ElSelect } from "element-plus";
import { createLogger } from "vuex";
export default {
    name: "Recharge",
    components: {
        MemberTitle
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const internalInstance = getCurrentInstance();
        const store = useStore();
        const opening = ref(false);
        const companyName = ref("");
        const enterpriseCode = ref("");
        const enterpriseLegalCode = ref("");
        const isShow = ref(1);
        const isarrow1 = ref(true);
        const isarrow2 = ref(true);
        const isarrow3 = ref(true);
        // 银行表单
        const bankForm = ref({
            acctOpenBranchName: "", //开户行名称
            acctOpenBranchCode: "",
            isFirm: true, //企业-true,个人-false
            agencyClientGlobalId: "", //经办人证件号
            agencyClientGlobalType: "", //经办人证件类型
            agencyClientName: "", //经办人姓名
            bankCardNo: "", //银行卡号
            bankType: "", //银行类型 1本行 2外行
            enterpriseLegalName: "", //法人姓名
            mobile: "", //手机号
            reprGlobalId: "", //法人证件号
            reprGlobalType: "1", //法人证件类型 1-身份证；3-港澳回乡证；5-台胞证;4-中国护照;19-外国护照
            name: ""
        });
        // 银行表单
        const bankForms = ref({
            acctOpenBranchName: "", //开户行名称
            bankCardNo: "", //银行卡号
            mobile: "", //手机号
            money: "0.01"
        });
        const bindbankList = ref(false); //该用户是否已有绑定好的银行卡
        const isBinding = ref(1); //判断绑定页面显示隐藏
        const router = useRouter();
        const next = () => {
            isBinding.value = false;
        };
        const onSubmit = () => {
            console.log("submit!");
        };
        //返回上一页
        const handleBack = () => {
            router.go(-1);
        };
        // 打款验证
        // 获得省市列表
        const bankList = reactive({ data: [] });
        const bankName = ref("");
        let bankCode = ref(null);
        let nodeList = ref([]);
        let nodeItem = ref("");

        const getNodeList = () => {
            proxy.$get("api/pay/common/queryProvince", { bankName: bankName.value }).then((res) => {
                if (res.code == 200) {
                    nodeList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        let cityList = ref({});
        let cityItem = ref("");
        let cityvalue = ref("");
        const selectBank_o = (item) => {
            cityBankItem.value = item.bankLname;
            cityvalue.value = item.cityName;
            cityItem.value = item.cityCode;
        };
        const getCityList = () => {
            let param = {};
            param.provinceCode = nodeItem.value ? nodeItem.value : "";
            param.provinceName = cityvalue.value ? cityvalue.value : "";
            proxy.$get("api/pay/common/queryCity", param).then((res) => {
                if (res.code == 200) {
                    cityItem.value = "";
                    cityList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        let cityBankList = ref([]);
        let cityBankItem = ref("");
        const getCityBankList = () => {
            let param = {};
            param.cityCode = cityItem.value;
            param.bankCode = bankCode.value;
            param.nodeCode = nodeItem.value;
            if (cityBankItem.value) {
                param.keyword = cityBankItem.value;
            }
            proxy.$get("api/pay/common/queryBankBranch", param).then((res) => {
                if (res.code == 200) {
                    cityBankList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        watch(cityItem, () => {
            if (bindbankList.value) {
                cityBankItem.value = "";
            }
            getCityBankList();
        });
        watch(nodeItem, () => {
            if (bindbankList.value) {
                cityBankItem.value = "";
                cityvalue.value = "";
            }
            getCityList();
        });
        getNodeList();
        //获取该用户下是否已有绑定银行卡
        const getBindbankList = () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    if (res.data.length > 0) bindbankList.value = true;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBindbankList();
        // 银行卡名称start
        // 获取银行列
        const getBankList = () => {
            proxy.$get("api/pay/common/queryBankOrg").then((res) => {
                if (res.code == 200) {
                    setTimeout(() => {
                        bankList.data = res.data;
                    });
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankList();
        // 防抖函数
        const inputName = (type) => {
            //控制只能输入汉字字符
            bankName.value = bankName.value.replace(/[^\u4e00-\u9fa5]/g, "");
            cityBankItem.value = cityBankItem.value.replace(/[^\u4e00-\u9fa5]/g, "");
            // 防抖
            if (type == "cityBank") {
                let timer;
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                timer = setTimeout(() => {
                    getCityBankList();
                    clearTimeout(timer);
                    timer = null;
                }, 500);
            }
            let timer;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                proxy
                    .$get("api/pay/common/queryBankOrg", {
                        bankName: bankName.value
                    })
                    .then((res) => {
                        console.log(222222);
                        if (res.code == 200) {
                            bankList.data = res.data;
                        } else {
                            ElMessage.warning(res.msg);
                        }
                    });
                clearTimeout(timer);
                timer = null;
            }, 500);
        };

        // 防抖函数
        const inputName_o = (type) => {
            //控制只能输入汉字字符
            cityvalue.value = cityvalue.value.replace(/[^\u4e00-\u9fa5]/g, "");
            // 防抖
            if (type == "cityItems") {
                let timer;
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                timer = setTimeout(() => {
                    getCityList();
                    clearTimeout(timer);
                    timer = null;
                }, 500);
            }
            let timer;
            // if (timer) {
            //   clearTimeout(timer);
            //   timer = null;
            // }
            timer = setTimeout(() => {
                proxy
                    .$get("api/pay/common/queryBankOrg", {
                        bankName: bankName.value
                    })
                    .then((res) => {
                        console.log(33333);
                        if (res.code == 200) {
                            bankList.data = res.data;
                        } else {
                            ElMessage.warning(res.msg);
                        }
                    });
                clearTimeout(timer);
                timer = null;
            }, 500);
        };
        const is_show = ref(false);
        let inputRef = ref(null);
        const selectName = (item) => {
            console.log(item);
            bankName.value = item.bankName;
            bankCode.value = item.bankCode;
            // bankForm.value.bankCode = item.bankCode
            is_show.value = item.bankName;
            isFocus.value = false;
        };
        // 选择开户行
        let cityBankCode = ref("");
        const selectBank = (item) => {
            cityBankItem.value = item.bankName;
            cityBankCode.value = item.bankCode;
        };
        const isFocus = ref(false);
        const iscityBankFocus = ref(false);
        const iscityBankFocus_o = ref(false);
        const inputFocus = (type) => {
            if (type == "cityBank") {
                iscityBankFocus.value = true;
                isarrow3.value = false;
            } else if (type == "cityItems") {
                iscityBankFocus_o.value = true;
                isarrow2.value = false;
            } else {
                isFocus.value = true;
                isarrow1.value = false;
            }
        };
        // 失去焦点
        const inputBlur = (type) => {
            if (type == "cityBank") {
                iscityBankFocus.value = false;
                isarrow3.value = true;
            } else if (type == "cityItems") {
                iscityBankFocus_o.value = false;
                isarrow2.value = true;
            } else {
                isFocus.value = false;
                isarrow1.value = true;
                internalInstance.ctx.$forceUpdate();
            }
            // getNodeList();  //初始化获取省数据，暂时注释2024 10 14
        };
        // 提交表单
        const subBank = () => {
            if (is_show.value != bankName.value) {
                ElMessage.warning("请选择正确的银行名称");
                return;
            }
            if (!opening.value) {
                if (!bankName.value.trim()) {
                    ElMessage.warning("请输入银行名称");
                    return;
                }
                if (!bankForms.value.bankCardNo.trim()) {
                    ElMessage.warning("请输入银行卡号");
                    return;
                }
                if (bankForms.value.bankCardNo.length < 8 || bankForms.value.bankCardNo.length > 30) {
                    ElMessage.warning("银行卡号长度必须在8到30之间");
                    return;
                }
                var num = /^\d*$/;
                if (!num.exec(bankForms.value.bankCardNo)) {
                    ElMessage.warning("银行卡号必须全为数字");
                    return;
                }
                //开头6位
                var strBin =
                    "10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99";
                if (strBin.indexOf(bankForms.value.bankCardNo.substring(0, 2)) == -1) {
                    ElMessage.warning("请输入正确的银行卡号");
                    return;
                }
                if (!bankForms.value.mobile.trim()) {
                    ElMessage.warning("请输入手机号");
                    return;
                }
                isBinding.value = 2;
            } else {
                if (!bankName.value) {
                    ElMessage.warning("请输入银行名称");
                    return;
                }
                if (!nodeItem.value) {
                    ElMessage.warning("请选择开户银行所在省");
                    return;
                }
                if (!cityItem.value) {
                    ElMessage.warning("请选择开户银行所在市");
                    return;
                }
                if (!cityBankItem.value) {
                    ElMessage.warning("请选择开户行");
                    return;
                }
                if (!bankForm.value.bankCardNo.trim() || bankForm.value.bankCardNo.length < 8) {
                    ElMessage.warning("请输入8-30位银行卡号");
                    return;
                }
                if (!bankForm.value.mobile.trim()) {
                    ElMessage.warning("请输入手机号");
                    return;
                }
                if (!bankForm.value.enterpriseLegalName.trim()) {
                    ElMessage.warning("请输入法人姓名");
                    return;
                }
                if (!bankForm.value.reprGlobalType) {
                    ElMessage.warning("请选择法人证件类型");
                    return;
                }
                if (!bankForm.value.reprGlobalId.trim()) {
                    ElMessage.warning("请输入法人证件号");
                    return;
                }
                // if(!bankForm.value.agencyClientGlobalType){
                //     ElMessage.warning('请选择经办人证件类型')
                //     return
                // }
                // if(!bankForm.value.agencyClientGlobalId){
                //     ElMessage.warning('请输入经办人证件号')
                //     return
                // }
                // proxy
                // .$get(
                //   "/v3/member/front/member/isBindCheckCorp",
                //   {bankCardNo:bankForm.value.bankCardNo}
                // )
                // .then(res => {
                //     isShow.value = res.data
                // });
                isShow.value = 1;
                isBinding.value = 2;
            }
        };
        // 银行名称 end
        // 绑卡成功
        const nextTip = () => {
            // 判断是否可以充值
            ElMessageBox.confirm("绑卡成功，请设置您的支付密码", "提示", {
                confirmButtonText: "前往设置 >",
                // cancelButtonText: '立即开户>',
                confirmButtonClass: "dilog_button",
                showCancelButton: false,
                // cancelButtonClass: 'classDilog',
                // type: 'warning',
                center: true
                // confirmButtonClass: '',
            })
                .then(() => {
                    router.push("/member/openAccount");
                    return;
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "Delete canceled"
                    });
                });
            // 判断是否可以充值end
        };

        // 获取验证码
        const descNum = ref(0);
        let isFlag = ref(false);
        const testPhoneNum = ref();
        const bankMoney = ref();
        const bankNum = ref();
        const getTestNum = () => {
            if (isFlag.value == true) {
                return;
            }
            if (descNum.value) {
                return;
            }
            isFlag.value = true;
            // const timedesc = setInterval(() => {
            //     descNum.value -= 1
            // }, 1000);
            // setTimeout(() => {
            //     isFlag.value = false
            //     descNum.value = 60
            //     clearInterval(timedesc)
            // }, 6000)
            let param = {};
            if (opening.value) {
                param.isFirm = bankForm.value.isFirm;
                param.firmBankName = bankName.value;
                param.acctOpenBranchName = cityBankItem.value;
                param.openingBank = cityBankCode.value;
                param.bankCity = nodeItem.value;
                param.bankProvince = nodeItem.value;
                param.agencyClientName = bankForm.value.agencyClientName;
                param.firmBankAccount = bankForm.value.bankCardNo;
                param.bankType = bankForm.value.bankType;
                param.corporateName = bankForm.value.enterpriseLegalName;
                param.userMobile = bankForm.value.mobile;
                param.corporateCode = bankForm.value.reprGlobalId;
                param.reprGlobalType = bankForm.value.reprGlobalType;
                param.firmCode = enterpriseCode.value;
                param.firmName = companyName.value;
            } else {
                param.isFirm = !bankForm.value.isFirm;
                param.firmBankName = bankName.value;
                // param.acctOpenBranchName = bankName.value;
                param.firmBankAccount = bankForms.value.bankCardNo;
                param.userMobile = bankForms.value.mobile;
            }

            proxy.$post("api/pay/wallet/authentication/number", JSON.stringify(param), "json").then((res) => {
                if (res.code == 200) {
                    if (isShow.value == 2) {
                        ElMessage.success(res.msg);
                        router.push("/member/bankCard");
                    }
                    ElMessage.success(res.msg);
                    descNum.value = 120;
                    countDown();
                    isBinding.value = 2;
                    isFlag.value = false;
                } else {
                    ElMessage.warning(res.msg);
                    isFlag.value = false;
                }
            });
        };
        const timeOutId = ref(""); //定时器的返回值
        //倒计时
        const countDown = () => {
            descNum.value--;
            if (descNum.value == 0) {
                isFlag.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        const sureBind = () => {
            let param = {};
            if (opening.value) {
                if (isShow.value == 1) {
                    if (!testPhoneNum.value) {
                        ElMessage.warning("请输入验证码");
                        return;
                    }
                }
                if (!bankMoney.value) {
                    ElMessage.warning("请输入金额");
                    return;
                }
                param.firmBankName = bankName.value;
                param.openingBank = cityBankCode.value;
                param.bankCity = nodeItem.value;
                param.bankProvince = nodeItem.value;
                param.agencyClientName = bankForm.value.agencyClientName;
                param.firmBankAccount = bankForm.value.bankCardNo;
                param.bankType = bankForm.value.bankType;
                param.corporateName = bankForm.value.enterpriseLegalName;
                param.userMobile = bankForm.value.mobile;
                param.corporateCode = bankForm.value.reprGlobalId;
                param.reprGlobalType = bankForm.value.reprGlobalType;
                param.authenticationNumber = testPhoneNum.value;
                param.remittanceAmount = bankMoney.value; //打款金额<0.5
                param.isFirm = bankForm.value.isFirm;
                param.firmCode = enterpriseCode.value;
                param.firmName = companyName.value;
                // param.cartId = bankNum.value
            } else {
                if (isShow.value == 1) {
                    if (!testPhoneNum.value) {
                        ElMessage.warning("请输入验证码");
                        return;
                    }
                    param.authenticationNumber = testPhoneNum.value;
                }
                param.firmBankName = bankName.value;
                param.firmBankAccount = bankForms.value.bankCardNo;
                param.userMobile = bankForms.value.mobile;
                param.remittanceAmount = bankForms.value.money;
            }
            proxy.$post("api/pay/wallet/bank/card/insert", JSON.stringify(param), "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    router.push("/member/bankCard");
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        //获取入驻详情接口
        const getDetail = () => {
            console.log(store.state, 111);
            let memberInfo = store.state.memberInfo;
            enterpriseCode.value = memberInfo.corp.corpCode;
            enterpriseLegalCode.value = memberInfo.enterpriseLegalCode;
            companyName.value = memberInfo.corp.corpName;
            bankForm.value.enterpriseLegalName = memberInfo.corp.corpLegalName;
            bankForm.value.reprGlobalId = memberInfo.corp.corpLegalCode;
            // bankName.value = bindbankList.value?'':res.data.vendorExtend.enterpriseBankName;
            // nodeItem.value = bindbankList.value?'':res.data.vendorExtend.nodeCodeName;
            // cityvalue.value = bindbankList.value?'':res.data.vendorExtend.cityCodeName;
            // cityBankItem.value = bindbankList.value?'':res.data.vendorExtend.enterpriseBankOpenAddress;
            bankForm.value.bankCardNo = memberInfo.corp.corpBankAccount;
            bankForm.value.mobile = memberInfo.corp.corpContactMobile;
            // bankName.value = res.data.vendorExtend.enterpriseProvinceCode;
            getDetails();
        };

        const getDetails = () => {
            proxy.$get("api/pay/common/queryBankOrg", { bankName: bankName.value }).then((res) => {
                if (res.code == 200) {
                    bankList.data = res.data;
                    res.data.forEach((item) => {
                        if (item.bankName == bankName.value) {
                            bankName.value = item.bankName;
                            is_show.value = item.bankName;
                            bankCode.value = item.bankCode;
                        }
                    });
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        const getInfo = () => {
            proxy.$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId }).then((res) => {
                if (res.code == 200) {
                    if (res.data.memberUserType == 0) {
                        bankForms.value.mobile = res.data.memberMobile;
                        opening.value = false;
                    } else {
                        if (res.data.isLookWallet) {
                            getDetail();
                            opening.value = true;
                        } else {
                            ElMessage.warning("暂无权限访问");
                            setTimeout(() => {
                                router.push(
                                    {
                                        path: "/member/index"
                                    },
                                    700
                                );
                            });
                        }
                    }
                }
            });
        };

        onMounted(() => {
            is_show.value = false;
            getInfo();
        });
        return {
            is_show,
            isFlag,
            descNum,
            handleBack,
            getDetails,
            getTestNum,
            isBinding,
            next,
            onSubmit,
            bankForm,
            nextTip,
            inputFocus,
            inputBlur,
            opening,
            selectName,
            inputName,
            bankList,
            isFocus,
            bankName,
            nodeList, //省级列表
            nodeItem,
            cityItem,
            cityList,
            cityBankList,
            bankForms,
            cityBankItem,
            iscityBankFocus,
            selectBank,
            subBank,
            testPhoneNum,
            bankMoney,
            bankNum,
            sureBind,
            companyName,
            enterpriseCode,
            enterpriseLegalCode,
            countDown,
            timeOutId,
            iscityBankFocus_o,
            selectBank_o,
            inputName_o,
            cityvalue,
            isShow,
            isarrow1,
            isarrow2,
            isarrow3,
            bindbankList,
            getBindbankList
        };
    }
};
</script>

<style lang="scss">
.el-input-number.is-without-controls .el-input__inner {
    width: 266.6px;
    text-align: left;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}
.el-input__inner {
    line-height: 1px !important;
}
.dilog_button {
    background-color: #fff !important;
    color: #409eff !important;
    margin: 0 auto;
    border: none;

    &:hover {
        background-color: #fff;
        color: #409eff;
        border: none;
    }
}

.bank_bottom_Form {
    .el-cascader {
        width: 100%;
    }

    .el-input__inner {
        width: 302px;
        height: 31px;
    }
    .el-input__suffix {
        left: 272px !important;
    }
    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
    .alone {
        .el-input__inner {
            width: 266px;
        }
    }
    .aloneB {
        .el-input__inner {
            width: 242px;
        }
    }
}
</style>
<style lang="scss" scoped>
.el-button--goon {
    color: #fff;
    background-color: $colorMain;
}
.scrollbar-demo-item {
    height: 50px;
    text-align: left;
    border-radius: 4px;
    color: var(--el-color-primary);
    width: 100%;
}
.search {
    // position: relative;
    position: absolute;
    .search_dialog::-webkit-scrollbar {
        width: 10px;
    }
    .search_dialog::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        background: #909399;
    }
    .search_dialog::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        // background: #909399;
    }
    .search_dialog {
        // position: absolute;
        background-color: white;
        z-index: 9999;
        min-width: 302px;
        max-height: 150px;
        color: var(--el-text-color-regular);
        border-radius: var(--el-border-radius-base);
        background: white;
        padding: 6px 0;
        box-shadow: var(--el-box-shadow-light);
        box-sizing: border-box;
        overflow: auto;

        div {
            width: 100%;
            height: 34px;
            line-height: 34px;
            cursor: pointer;
            padding-left: 20px;

            &:hover {
                background-color: var(--el-background-color-base);
            }
        }
    }
}

.bank_bottom_Form {
    padding-left: 280px;
    position: relative;
    .timeOut1 {
        position: absolute;
        top: 5px;
        left: 156px;
        width: 109px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background-color: $colorMain2;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }
    .timeOut {
        position: absolute;
        top: 5px;
        left: 133px;
        width: 109px;
        height: 30px;
        border-radius: 0 4px 4px 0;
        background-color: $colorMain2;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }
    .next1 {
        width: 170px;
        background-color: $colorMain;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        line-height: 31px;
        color: #fff;
        margin-left: 170px;
        cursor: pointer;
        margin-top: 30px;
    }
    .next2 {
        width: 170px;
        background-color: $colorMain;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        line-height: 31px;
        color: #fff;
        margin-left: 156px;
        cursor: pointer;
        margin-top: 30px;
    }
    .next {
        width: 170px;
        background-color: $colorMain;
        height: 31px;
        border-radius: 5px;
        text-align: center;
        line-height: 31px;
        color: #fff;
        margin-left: 102px;
        cursor: pointer;
        margin-top: 30px;
    }
}

.sld_bankCard {
    width: 1020px;
    float: left;
    margin-left: 10px;

    .unbinding {
        height: 40px;
        line-height: 40px;
        text-align: right;
        width: 307px;
        color: rgba(30, 119, 240, 1);
        cursor: pointer;
    }

    .sld_bankCard_con {
        background: #fff;
        height: 920px;
        // padding-bottom: 50px;
        padding: 0 20px;

        .bank_top2 {
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                &:nth-child(1) {
                    line-height: 70px;
                    color: #1c1c1c;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                }
            }
        }
    }
}

.bank_center {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;

    .bank_state {
        display: flex;
        align-items: center;
        .step {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 28px;
            background-size: 100% 100%;
            // color: white;
            font-size: 14px;
            margin-right: 20px;

            &:nth-last-of-type(1) {
                margin-right: 0;
            }

            .line {
                width: 70px;
                height: 1px;
                background: #51b953;
                display: inline-block;
            }

            .no_line {
                width: 70px;
                height: 1px;
                display: inline-block;
                background: #eeeeee;
            }

            .sel_line {
                background: #51b953;
            }

            i {
                font-size: 16px;
                color: #33ad36;
                margin: 0 10px 0 20px;
            }

            .no_sel {
                color: #e2e2e2;
            }

            .no_step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }

            .step_text {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .red_state {
        background-color: $colorMain;
        color: rgba(255, 255, 255, 1);
    }

    .white_state {
        position: relative;
        width: 123px;
        height: 123px;
        line-height: 123px;
        // background-color: rgba(240, 39, 39, 1);
        // color: rgba(255, 255, 255, 1);
        // color: black;
        font-size: 20px;
        text-align: center;
        font-family: Roboto;
        border-radius: 50%;
        border: 1px solid rgba(187, 187, 187, 1);

        span {
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translate(-50%, -50%);
            line-height: 20px;
            font-size: 14px;
            color: black;
            width: 100px;
        }
    }
}
</style>
